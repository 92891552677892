import React from 'react'

const NotFoundPage = () => (
  <div style={{paddingTop: "8em", paddingLeft: "2em", fontSize: "130%"}}>
    <h1>404</h1>
    <p>Page not found</p>
  </div>
)

export default NotFoundPage
